<template>
  <div class="text-general mb-3">
    <h1>Algo-2015</h1>

    <p>
      (Boris Kirikov,
      <a href="mailto:Boris.Kirikov@edu.mamk.fi">Boris.Kirikov@edu.mamk.fi</a>)
    </p>

    <p>Lectures on thursdays at 14:30 in MB210.</p>

    <p>
      <a :href="`${publicPath}algo-2015/plan.pdf`">Plan</a> of lections. Any
      requests/suggestions about topics? Write on my email.
    </p>

    <ul>
      <li>
        Lecture 1 (17.9.2015):
        <a :href="`${publicPath}algo-2015/slides-1.pdf`">slides</a>,
        <a :href="`${publicPath}algo-2015/task-1.pdf`">task</a>
      </li>
      <li style="list-style: none; display: inline">
        <ol>
          <li>Introduction</li>
          <li>
            Fibonacci numbers, basic concepts about algorithms, solving
            recurrent equations
          </li>
          <li>
            Complexity, O-notation and Co, real working time vs estimations
          </li>
          <li>Devide-and-conquer: binary search, merge sort</li>
          <li>Two pointers method, prooving correctness and complexity</li>
        </ol>
      </li>

      <li>
        Lecture 2 (24.9.2015):
        <a :href="`${publicPath}algo-2015/slides-2.pdf`">slides</a>,
        <a :href="`${publicPath}algo-2015/task-2.pdf`">task</a>
      </li>
      <li style="list-style: none; display: inline">
        <ol>
          <li>Methods for estimating sums</li>
          <li>Generating functions and master theorem</li>
          <li>Devide-and-conquer-2: inversions, fast multiplication</li>
        </ol>
      </li>

      <li>
        Lecture 3 (1.10.2015):
        <a :href="`${publicPath}algo-2015/slides-3.pdf`">slides</a>,
        <a :href="`${publicPath}algo-2015/task-3.pdf`">task</a>
      </li>
      <li style="list-style: none; display: inline">
        <ol>
          <li>Defining Matricies</li>
          <li>Matrix operations</li>
          <li>Graphs, representations</li>
          <li>Spanning trees</li>
          <li>Traversing graphs</li>
        </ol>
      </li>

      <li>
        Lecture 4 (15.10.2015):
        <a :href="`${publicPath}algo-2015/slides-4.pdf`">slides</a>,
        <a :href="`${publicPath}algo-2015/task-4.pdf`">task</a>
      </li>

      <li style="list-style: none; display: inline">
        <ol>
          <li>Linear space</li>
          <li>Linear mapping</li>
          <li>Gauss method</li>
          <li>LU-decomposition</li>
        </ol>
      </li>

      <li>
        Lecture 5 (29.10.2015):
        <a :href="`${publicPath}algo-2015/slides-5.pdf`">slides</a>,
        <a :href="`${publicPath}algo-2015/code-5.html`">code</a>
      </li>

      <li style="list-style: none; display: inline">
        <ol>
          <li>What we haven't done in lecture 4</li>
        </ol>
      </li>
    </ul>

    <p>**END**</p>
    <hr />

    <!--    <div id="disqus_thread"></div>-->
  </div>
</template>

<script>
export default {
  name: "AlgoLections",
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  // created() {
  //     var dsq = document.createElement('script');
  //     dsq.type = 'text/javascript';
  //     dsq.async = true;
  //     dsq.src = '//algo-2015.disqus.com/embed.js';
  //     document.getElementsByTagName('head')[0].appendChild(dsq);
  // }
};
</script>

<style scoped></style>
